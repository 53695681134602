import config from  './config.js';

main();

async function main() {
    try {

        const messages = {    // dummy post data
            'api_key': "none"
        };

        const response = await fetch(config.HUBWorkerUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(messages)
        })

        const responseJson = await response.json();


        let resultsText = '';
        if(!responseJson){
            resultsText= `<h2 class="card-title error">Error</h2> <p class="error">Sorry, something went wrong. Please try again.</p>`;
        } else {

            //console.log(responseJson.message);

            const content = responseJson.message.replace(/\$\s*([\d,]+(?:\.\d{2})?)/g, '<b>$&</b>');
    
            const sections = content.split(/Price:|Date:|Prediction:|Summary:|Details:|Conclusion:/).map(section => section.trim());
     
            const parsedData = {
                price: sections[1],
                date: sections[2],
                prediction: sections[3].replace('Long-Term', '<br/><br/>Long-Term'),
                summary: sections[4],
                details: sections[5],
                conclusion: sections[6]
            }


            resultsText= `<div class="small text-muted">Analysis generated at: ${parsedData.date} price was: ${parsedData.price}</div>
                            <div class="mt-2">
                                <h3 class="card-title">Predicton</h3>
                                <p> ${parsedData.prediction}</p>
                                <h3 class="card-title">Summary</h3>
                                <p> ${parsedData.summary}</p>
                                <h3 class="card-title">Details</h3>
                                <p> ${parsedData.details}</p>
                                <h3 class="card-title">Conclusion</h3>
                                <p> ${parsedData.conclusion}</p>
                            </div>
            `;
        }

        var typed = new Typed('#analysis', {
            strings: [resultsText],
            startDelay: 250,
            typeSpeed: 0,
            backSpeed: 0,
            loop: false,
            showCursor: true,
            fadeOut: true
        });



    } catch (error) {
       console.error('Error in main function.', error.message);
       //resultContainer.innerHTML = "Sorry, something went wrong. Please try again.";
    }
  }


